import React from "react";
import SiteConfig from "../../config/SiteConfig.json";
import { Link } from "gatsby";
import {
  Container,
  Card,
  CardTitle,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import _ from "lodash";
import Header from "../components/header";
import Seo from "../components/SEO";
import Layout from "../components/layout";

class TranslatedIndexPage extends React.Component {
  render() {
    let json = this.props.pageContext.json;
    let lang = this.props.pageContext.lang;
    let brandPostsTitlesLength = 0;

    json = _.sortBy(json, o => {
      if (lang === "en") {
        return o.category;
      } else {
        return o.translatedCategory;
      }
    });

    let viewText;
    switch (lang) {
      case "ru":
        viewText = "Посмотреть все";
        break;
      case "es":
        viewText = "Ver todo";
        break;
      case "it":
        viewText = "Guarda tutto";
        break;
      case "de":
        viewText = "Alle ansehen";
        break;
      case "pt":
        viewText = "Ver tudo";
        break;
      case "fr":
        viewText = "Voir tout";
        break;
      case "ja":
        viewText = "すべて見る";
        break;
      case "cs":
        viewText = "Zobrazit vše";
        break;
      case "id":
        viewText = "Lihat semua";
        break;
      case "nb":
        viewText = "Se alt";
        break;
      case "hi":
        viewText = "सभी को देखें";
        break;
      case "fi":
        viewText = "Näytä kaikki";
        break;
      case "ko":
        viewText = "모두보기";
        break;
      case "hu":
        viewText = "Összes megtekintése";
        break;
      case "hr":
        viewText = "Pogledaj sve";
        break;
      case "el":
        viewText = "Προβολή όλων";
        break;
      case "nl":
        viewText = "Bekijk alles";
        break;
      case "pl":
        viewText = "Pokaż wszystkie";
        break;
      case "tr":
        viewText = "Hepsini gör";
        break;
      case "he":
        viewText = "צפה בהכל";
        break;
      case "ar":
        viewText = "مشاهدة الكل";
        break;
      case "zh":
        viewText = "查看全部";
        break;
      default:
        viewText = "View All";
    }
    return (
      <Layout lang={lang}>
        <Seo
          postLanguage={lang}
          postDescription={SiteConfig.seo.siteDescription}
        />
        <Header lang={lang} />
        <Container>
          <div className="card-container">
            {json.map(jsonPost => {
              brandPostsTitlesLength = 0;
              let categoryMeta;
              if (lang !== "en") {
                categoryMeta = `/${lang}/category/${_.toLower(jsonPost.category)
                  .split(" ")
                  .join("-")}`;
              } else {
                categoryMeta = `/category/${_.toLower(jsonPost.category)
                  .split(" ")
                  .join("-")}`;
              }
              return (
                <Card
                  body
                  className="card-body"
                  key={`${lang}-${jsonPost.category}`}
                  style={{
                    borderRadius: SiteConfig.content.homePage.card.borderRadius,
                    boxShadow: SiteConfig.content.homePage.card.boxShadow,
                    MozBoxShadow: SiteConfig.content.homePage.card.boxShadow
                  }}
                >
                  <CardTitle className="text-center font-weight-bold">
                    <Link className="post-title" to={categoryMeta}>{`${
                      jsonPost.translatedCategory
                        ? _.replace(
                            _.replace(
                              jsonPost.translatedCategory,
                              /@brand/g,
                              SiteConfig.brand
                            ),
                            "Amp",
                            "&"
                          )
                        : _.replace(
                            _.replace(
                              jsonPost.category,
                              /@brand/g,
                              SiteConfig.brand
                            ),
                            "Amp",
                            "&"
                          )
                    }`}</Link>
                  </CardTitle>
                  <ListGroup flush>
                    {jsonPost.posts.map((post, i) => {
                      if (
                        SiteConfig.brand === "Blix" &&
                        !_.includes(post.title, "BlueMail") &&
                        !_.includes(post.title, "TypeApp")
                      ) {
                        brandPostsTitlesLength += 1;
                      } else if (
                        SiteConfig.brand === "BlueMail" &&
                        !_.includes(post.title, "Blix") &&
                        !_.includes(post.title, "TypeApp")
                      ) {
                        brandPostsTitlesLength += 1;
                      } else if (
                        SiteConfig.brand === "TypeApp" &&
                        !_.includes(post.title, "Blix") &&
                        !_.includes(post.title, "BlueMail")
                      ) {
                        brandPostsTitlesLength += 1;
                      }

                      if (i < 4) {
                        if (
                          SiteConfig.brand === "Blix" &&
                          !_.includes(post.title, "BlueMail") &&
                          !_.includes(post.title, "TypeApp")
                        ) {
                          return (
                            <ListGroupItem key={post.meta} className="d-flex">
                              <span className="arrow-icon">&#187;</span>
                              <Link className="post-title" to={post.meta}>
                                {_.replace(
                                  post.title,
                                  /@brand/g,
                                  SiteConfig.brand
                                )}
                              </Link>
                            </ListGroupItem>
                          );
                        } else if (
                          SiteConfig.brand === "BlueMail" &&
                          !_.includes(post.title, "Blix") &&
                          !_.includes(post.title, "TypeApp")
                        ) {
                          return (
                            <ListGroupItem key={post.meta} className="d-flex">
                              <span className="arrow-icon">&#187;</span>
                              <Link className="post-title" to={post.meta}>
                                {_.replace(
                                  post.title,
                                  /@brand/g,
                                  SiteConfig.brand
                                )}
                              </Link>
                            </ListGroupItem>
                          );
                        } else if (
                          SiteConfig.brand === "TypeApp" &&
                          !_.includes(post.title, "Blix") &&
                          !_.includes(post.title, "BlueMail")
                        ) {
                          return (
                            <ListGroupItem key={post.meta} className="d-flex">
                              <span className="arrow-icon">&#187;</span>
                              <Link className="post-title" to={post.meta}>
                                {_.replace(
                                  post.title,
                                  /@brand/g,
                                  SiteConfig.brand
                                )}
                              </Link>
                            </ListGroupItem>
                          );
                        }
                      }
                      return "";
                    })}
                    {jsonPost.posts.length >= 5 && (
                      <ListGroupItem>
                        <Link
                          className="post-view"
                          to={categoryMeta}
                        >{`${viewText} (${brandPostsTitlesLength})`}</Link>
                      </ListGroupItem>
                    )}
                  </ListGroup>
                </Card>
              );
            })}
          </div>
        </Container>
      </Layout>
    );
  }
}

export default TranslatedIndexPage;
